@import '~antd/dist/antd.less';

.logo {
  font-family: Sansita;
  color: @primary-color;
  margin-right: 50px;
  font-size: 29px;
  font-weight: 800;

  float: left;
  width: 140px;
  height: 31px;
}

.layout {
  min-height: 100%;
}

header {
  border-bottom: 1px solid #f0f0f0;
}

.header-menu {
  border-bottom: none;
}

.main-content {
  padding: 25px 50px;
}

.footer {
  background: fade(@primary-color, 50%);
}

.fancy-font {
  font-family: Sansita;
}

.form-or {
  margin: 0 40px;
  font-weight: bold;
  font-style: italic;
}

.about-me {
  background: fade(#028090, 75%);
}

.patterns-preview {
  background: fade(#AD40FF, 75%);
  margin: 25px 50px;
  padding: 25px;
}

.fun-font {
  font-family: Sansita;
  font-weight: 800;
}
@primary-color: #FF4D68;@layout-header-background: #FFFFFF;@layout-body-background: #FFFFFF;@link-color: #028090;